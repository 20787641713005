import client, { AxiosResponse } from 'axios'
import { OrderBean } from '../../sharePortal/orderManagement/bean/orderBean'
import { Pageable } from '../../common/bean/Pageable'

const url = `/api/v2/orders`
const OrderServiceV2 = {
  findAll({
    page = 0,
    size = 20,
    sort = 'id,desc',
    params = {},
  }): Promise<AxiosResponse<Pageable<OrderBean>>> {
    const rParam = Object.fromEntries(
      Object.entries({
        ...params,
        page,
        size,
        sort,
      }).filter(([, value]) => !!value)
    )
    return client.get(url, {
      params: rParam,
    })
  },
}

export default OrderServiceV2
