import React, { useEffect, useMemo, useState } from 'react'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import OrderBgColorByStatus from '../../common/constant/orderBgColorByStatus'
import { useHistory, useLocation } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import userProfileService from '../../common/service/userProfileService'
import OrderService from '../../agentPortal/orderManagement/orderService'
import { OrderBean } from './bean/orderBean'
import DateUtils from '../../common/dateUtils'
import { useDebounce } from '../../common/useDebounce'
import { useTypedSelector } from '../../redux/reducer'
import qs from 'qs'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { Alert, Autocomplete } from '@material-ui/lab'
import OrderStatus from '../../common/constant/orderStatus'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import { ExportToCsv, Options as csvOptions } from 'export-to-csv'
import GetAppIcon from '@material-ui/icons/GetApp'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import { UserCodeBean } from '../../common/service/UserCodeBean'
import CommonUtils from '../../common/commonUtils'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { isAfter, isBefore } from 'date-fns'
import UserBean from '../userManagement/bean/userBean'
import _ from 'lodash'
import { OrderDisplayModel } from '../../adminPortal/settings/component/order/model/OrderDisplayModel'
import { OrderFilterBean } from './bean/orderFilterBean'
import { Cancel, CheckCircle } from '@material-ui/icons'
import { filterOrderStatus } from './bean/order.constant'

const OrderManagementPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const filter: Record<string, string> = useMemo(() => {
    const {
      pageNumber = '0',
      product = '',
      productCategory = '',
      productPackage = '',
      agent = '',
      status = '',
      submittedDate = '',
      activatedDate = '',
      docCompleted = '',
      isSubmitted = '',
      submitDateFrom = '',
      submitDateTo = '',
      activatedDateFrom = '',
      activatedDateTo = '',
      orderStatus = filterOrderStatus.IN_PROGRESS,
    } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    return {
      pageNumber: pageNumber.toString(),
      product: product.toString(),
      productCategory: productCategory.toString(),
      productPackage: productPackage.toString(),
      agent: agent.toString(),
      status: status.toString(),
      submittedDate: submittedDate.toString(),
      activatedDate: activatedDate.toString(),
      docCompleted: docCompleted.toString(),
      submitDateFrom: submitDateFrom.toString(),
      submitDateTo: submitDateTo.toString(),
      activatedDateFrom: activatedDateFrom.toString(),
      activatedDateTo: activatedDateTo.toString(),
      orderStatus: orderStatus.toString(),
      isSubmitted: isSubmitted.toString(),
    }
  }, [location.search])

  const initFilterDetail: OrderFilterBean = {
    key: '',
    label: '',
    value: [],
  }

  const [orders, setOrders] = useState<OrderBean[]>([])
  const [triggerRefresh, setTriggerRefresh] = useState(true)
  const [filterString, setFilterString] = useState('')
  const [debounceString, setDebounceString] = useDebounce('', 500)
  const [agentMap, setAgentMap] = useState<Record<string, UserBean>>({})

  const [filterOption, setFilterOption] = useState<
    Record<string, OrderFilterBean>
  >({
    product: initFilterDetail,
    productCategory: initFilterDetail,
    productPackage: initFilterDetail,
    status: initFilterDetail,
    submittedDate: initFilterDetail,
    activatedDate: initFilterDetail,
    docCompleted: initFilterDetail,
    isSubmitted: initFilterDetail,
  })

  const isAgent: () => boolean = () => userProfileService.isAgent()

  const portalSettingOrderDisplayList = useTypedSelector(
    state => state.orderSettings.orderDisplayList
  )
  const userSettingOrderDisplayList = useTypedSelector(
    state => state.userSettings.orderDisplayList
  )

  const orderDisplayList = useMemo(() => {
    if (userSettingOrderDisplayList.length) return userSettingOrderDisplayList
    return portalSettingOrderDisplayList
  }, [portalSettingOrderDisplayList, userSettingOrderDisplayList])

  const portalSettingReducer = useTypedSelector(state => state.portalSetting)
  const userCode: UserCodeBean = useTypedSelector(
    state => state.portalSetting.userCode
  )

  // Paginator
  const rowPerPageOptions = portalSettingReducer.paginator.rowPerPageOptions
  const [rowsPerPage, setRowsPerPage] = React.useState(
    portalSettingReducer.paginator.defaultRowPerPage
  )
  const [page, setPage] = useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    const query: Record<any, any> = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
    query['pageNumber'] = `${newPage}`
    const newQuery = qs.stringify(query)
    history.replace({
      search: `?${newQuery}`,
    })
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))

    const query: Record<any, any> = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
    query['pageNumber'] = `${0}`
    const newQuery = qs.stringify(query)
    history.replace({
      search: `?${newQuery}`,
    })
  }
  useEffect(() => {
    if (orders.length) {
      setPage(Number(filter.pageNumber))
    }
  }, [filter.pageNumber, orders])
  // END Paginator

  function handleFilterChange(name: string, value: string) {
    const query: Record<any, any> = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
    query[name] = `${value}`
    query['pageNumber'] = '0'
    const newQuery = qs.stringify(query)
    history.replace({
      search: `?${newQuery}`,
    })
  }

  const handleFilterDateChange = (
    name: string,
    newDate: MaterialUiPickersDate | null
  ) => {
    const query: Record<any, any> = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
    query[name!!] = newDate?.toDateString()
    query['pageNumber'] = '0'
    const newQuery = qs.stringify(query)
    history.replace({
      search: `?${newQuery}`,
    })
  }

  const handleCompleteOrder = (order: OrderBean) => {
    dispatch(LoadingAction.open('CLOSING ORDER'))
    OrderService.completeOrder(order.id)
      .then(() => setTriggerRefresh(!triggerRefresh))
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  }
  const handleFilterClear = () => {
    const { pageNumber }: Record<any, any> = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
    const newQuery = qs.stringify({
      pageNumber,
    })
    history.replace({
      search: `?${newQuery}`,
    })
  }
  const handleOrderOnClick = (order: OrderBean) => {
    isAgent()
      ? history.push(`/agent-portal/order-detail/${order.id}`)
      : history.push(`/admin-portal/order-detail/${order.id}`)
  }
  const filteredOrder: OrderBean[] = useMemo(() => {
    if (Object.values(filter).every(v => !v) && !debounceString) {
      return orders
    }

    return orders
      .filter(value => {
        if (filter.isSubmitted === '') return true
        return (
          (filter.isSubmitted === 'YES' && value.submittedDate !== null) ||
          (filter.isSubmitted === 'NO' && value.submittedDate === null)
        )
      })
      .filter(value => {
        if (filter.docCompleted === '') return true
        return (filter.docCompleted === 'COMPLETE') === value.documentCompleted
      })
      .filter(value => {
        if (filter.product === '') return true
        return filter.product === value.product
      })
      .filter(value => {
        if (filter.productCategory === '') return true
        return filter.productCategory === value.productCategory
      })
      .filter(value => {
        if (filter.productPackage === '') return true
        return filter.productPackage === value.productPackage
      })
      .filter(value => {
        if (filter.agent === '') return true
        return filter.agent === value.refUser.username
      })
      .filter(value => {
        if (filter.status === '') return true
        return filter.status === value.orderStatus
      })

      .filter(value => {
        if (filter.submitDateFrom === '') {
          return true
        } else if (value.createdDate) {
          return isBefore(
            new Date(filter.submitDateFrom),
            new Date(value.createdDate)
          )
        } else {
          return false
        }
      })
      .filter(value => {
        if (filter.submitDateTo === '') {
          return true
        } else if (value.createdDate) {
          return isAfter(
            new Date(filter.submitDateTo),
            new Date(value.createdDate)
          )
        } else return false
      })
      .filter(value => {
        if (filter.activatedDateFrom === '') {
          return true
        } else if (value.activatedOn) {
          return isBefore(
            new Date(filter.activatedDateFrom),
            new Date(value.activatedOn)
          )
        } else return false
      })
      .filter(value => {
        if (filter.activatedDateTo === '') {
          return true
        } else if (value.activatedOn) {
          return isAfter(
            new Date(filter.activatedDateTo),
            new Date(value.activatedOn)
          )
        } else return false
      })

      .filter(value => {
        if (debounceString) {
          const code = CommonUtils.getUserCode(value.refUser, userCode)

          return [
            code,
            value?.id,
            value?.product,
            value?.productCategory,
            value?.productPackage,
            value?.price,
            value?.companyName,
            value?.companyRegNo,
            [
              value?.address,
              value?.unitNo,
              value?.streetType,
              value?.streetName,
              value?.section,
              value?.floorNo,
              value?.buildingName,
              value?.city,
              value?.state,
              value?.postcode,
            ]
              .map(v => v.toString().trim())
              .filter(v => !!v)
              .join(' '),
            value?.fullName,
            value?.nric,
            value?.email,
            value?.mobileNo,
            value?.telNo,
            value?.orderStatus,
            value?.remarks,
            value?.orderNo,
            value?.loginId,
            value?.eform,
            value?.documentUrl,
            value?.activatedOn,
            value?.createdDate,
            value?.createdBy,
            value?.modifiedDate,
            value?.modifiedBy,
            value?.refUser?.fullName,
            value?.invoiceNo,
            value?.alternativeNo,
            value?.adminRemarks,
            value?.m2uRemarks,

            value?.processingId,
            value?.accountNo,
            value?.sporaReferenceNumber,
            value?.ociTicketNumber,
            value?.sporaProcessingId,
          ].some(
            (v: any) =>
              !!v &&
              v.toString().toLowerCase().includes(debounceString.toLowerCase())
          )
        } else {
          return true
        }
      })
  }, [filter, debounceString, orders, userCode])

  useEffect(() => {
    OrderService.findAll(undefined, filter.orderStatus)
      .then(value => setOrders(value.data))
      .catch(catchErrorWithDispatch(dispatch))
      .finally()
  }, [dispatch, filter.orderStatus, triggerRefresh])
  useEffect(() => {
    const productSet = new Set<string>()
    const productCategorySet = new Set<string>()
    const productPackageSet = new Set<string>()

    orders.forEach(value => {
      productSet.add(value.product)
      productCategorySet.add(value.productCategory)
      productPackageSet.add(value.productPackage)
    })

    setFilterOption({
      docCompleted: {
        key: 'docCompleted',
        label: 'Doc Completed',
        value: ['COMPLETE', 'NOT COMPLETE'],
      },
      isSubmitted: {
        key: 'isSubmitted',
        label: 'Is Submitted',
        value: ['YES', 'NO'],
      },
      product: {
        key: 'product',
        label: 'Product',
        value: [...Array.from(productSet)],
      },
      productCategory: {
        key: 'productCategory',
        label: 'Product category',
        value: [...Array.from(productCategorySet)],
      },
      productPackage: {
        key: 'productPackage',
        label: 'Product package',
        value: [...Array.from(productPackageSet)],
      },
      status: {
        key: 'status',
        label: 'Status',
        value: OrderStatus.orderStatusDdl.map(o => o.key),
      },
    })
  }, [orders])

  useEffect(() => {
    const agentMap = orders.reduce<Record<number, UserBean>>(
      (previousValue, currentValue) => {
        const refUser = currentValue.refUser
        return {
          ...previousValue,
          [refUser.username]: refUser,
        }
      },
      {}
    )

    setAgentMap(agentMap)
  }, [orders])

  function handleDeleteOrder(orderId: number) {
    dispatch(LoadingAction.open('Deleting Order...'))
    OrderService.deleteOrderById(orderId)
      .then(() => {
        dispatch(SnackbarAction.open('Success Delete Order'))

        setTriggerRefresh(!triggerRefresh)
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  }

  function handleCompletePaymentClaim(id: number) {
    dispatch(LoadingAction.open('Set Complete Payment Claim...'))
    OrderService.completePaymentClaim(id)
      .then(() => setTriggerRefresh(!triggerRefresh))
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => dispatch(LoadingAction.close()))
  }

  function exportOrdersToCsv(order: OrderBean[]) {
    const s = DateUtils.getCurrentDateTime()
    const options: csvOptions = {
      filename: `Orders_${s}`,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Orders',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    }

    const csvExporter = new ExportToCsv(options)

    // sanitise data, make all undefined or null to ''
    //  and filter unused data
    const sanitisedOrder = order
      .map((input: OrderBean) =>
        Object.fromEntries(
          Object.entries(input).map(([key, value]) => {
            return value === undefined || value === null
              ? [key, '']
              : [key, value]
          })
        )
      )
      .map(input => {
        // rearrange columns and remove entity
        return {
          id: input.id,
          username: input.refUser.username,
          agentId: input.agentId,
          product: input.product,
          productCategory: input.productCategory,
          productPackage: input.productPackage,
          price: input.price,

          orderStatus: input.orderStatus,
          orderNo: input.orderNo,
          eform: input.eform,
          invoiceNo: input.invoiceNo,
          documentCompleted: input.documentCompleted,
          loginId: input.loginId,
          remarks: input.remarks,
          adminRemarks: input.adminRemarks,
          m2uRemarks: input.m2uRemarks,
          processingId: input.processingId,

          companyName: input.companyName,
          companyRegNo: input.companyRegNo,
          fullName: input.fullName,
          nric: input.nric,
          email: input.email,
          mobileNo: input.mobileNo,
          telNo: input.telNo,
          alternativeNo: input.alternativeNo,

          unitNo: input.unitNo,
          streetType: input.streetType,
          streetName: input.streetName,
          section: input.section,
          floorNo: input.floorNo,
          buildingName: input.buildingName,
          city: input.city,
          postcode: input.postcode,
          state: input.state,
          address: input.address,

          clawbackDate: input.clawbackDate,
          paymentClaimDate: input.paymentClaimDate,
          activatedOn: input.activatedOn,
          createdDate: input.createdDate,
          createdBy: input.createdBy,
          modifiedDate: input.modifiedDate,
          modifiedBy: input.modifiedBy,
        }
      })
    csvExporter.generateCsv(sanitisedOrder)
  }

  const renderOrder2 = (order: OrderBean) => {
    const orderBgColor = OrderBgColorByStatus[order.orderStatus]

    const displayModel = new OrderDisplayModel(
      order,
      CommonUtils.getUserCode(order.refUser, userCode)
    )

    return (
      <TableRow
        key={order.id}
        className={`hover:bg-gray-200 cursor-pointer`}
        id={order.id.toString()}
        onDoubleClick={e => {
          e.preventDefault()
          e.stopPropagation()
          handleOrderOnClick(order)
        }}
      >
        {orderDisplayList.map((value, index) => {
          if (_.isEqual(value, 'orderStatus')) {
            return (
              <TableCell key={index} className='truncate'>
                <span className={'p-1 rounded text-white ' + orderBgColor}>
                  {_.get(displayModel, value)}
                </span>
              </TableCell>
            )
          } else if (_.isEqual(value, 'documentCompleted')) {
            return (
              <TableCell key={index} className='truncate max-w-sm'>
                {_.get(displayModel, value) === 'YES' ? (
                  <CheckCircle className='text-green-400' />
                ) : (
                  <Cancel className='text-red-400' />
                )}
              </TableCell>
            )
          } else
            return (
              <TableCell key={index} className='max-w-sm'>
                {_.get(displayModel, value)}
              </TableCell>
            )
        })}

        <TableCell>
          <div className='flex space-x-3'>
            <Tooltip title='Edit Order'>
              <IconButton
                size={'small'}
                color='inherit'
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleOrderOnClick(order)
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>{' '}
            {order.orderStatus !== OrderStatus.POST_COMPLETE && !isAgent() && (
              <Tooltip title='Complete Order'>
                <span>
                  <IconButton
                    disabled={!order.documentCompleted}
                    size={'small'}
                    color='inherit'
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleCompleteOrder(order)
                    }}
                  >
                    <DoneAllIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {(userProfileService.isManager() || userProfileService.isAdmin()) &&
              !order.paymentClaimDate && (
                <Tooltip title={'Complete Payment Claim'}>
                  <IconButton
                    size={'small'}
                    color='inherit'
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      handleCompletePaymentClaim(order.id)
                    }}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                </Tooltip>
              )}
            {userProfileService.isManager() && (
              <Tooltip title={'Delete Order'}>
                <IconButton
                  size={'small'}
                  color='inherit'
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()

                    dispatch(
                      AlertDialogAction.open(
                        <>
                          Delete Order:{' '}
                          <span className='font-bold'>{order.id}</span>
                        </>,
                        <>
                          <div>Are you sure to delete this order?</div>
                          <div>
                            {order.fullName} ({order.nric})
                          </div>
                          <div>by {order.refUser.username}</div>
                        </>,
                        () => {
                          handleDeleteOrder(order.id)
                        }
                      )
                    )
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </TableCell>
      </TableRow>
    )
  }
  return (
    <div id='top'>
      <div className='absolute right-2 bottom-2'>
        <Fab
          size='small'
          color='primary'
          aria-label='to top'
          onClick={() => {
            document?.getElementById('top')?.scrollIntoView()
          }}
        >
          <VerticalAlignTopIcon />
        </Fab>
      </div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <Typography variant='h6' noWrap>
            Order Management
          </Typography>

          <Tooltip title={'Download CSV File'}>
            <Button
              variant={'outlined'}
              color='inherit'
              onClick={() => exportOrdersToCsv(filteredOrder)}
              startIcon={<GetAppIcon />}
            >
              Download CSV
            </Button>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Card className='m-8'>
        <CardHeader title='Filter' />
        <CardContent className='grid grid-cols-4 gap-4'>
          {Object.entries(filterOption).map(([k, filterDetail]) => {
            return (
              <FormControl key={k}>
                <InputLabel id={k}>{filterDetail.label}</InputLabel>
                <Select
                  id={k}
                  name={k}
                  value={filter[k]}
                  onChange={e =>
                    handleFilterChange(
                      e.target.name!!,
                      String(e.target.value!!)
                    )
                  }
                >
                  <MenuItem value={''}>
                    <em>None</em>
                  </MenuItem>
                  {filterDetail.value.map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          })}
          <DatePicker
            clearable
            disableToolbar
            format='dd MMM yyyy'
            name='submitDateFrom'
            label='Submit Date From'
            value={filter['submitDateFrom'] || null}
            onChange={(e: MaterialUiPickersDate) => {
              handleFilterDateChange('submitDateFrom', e)
            }}
          />
          <DatePicker
            clearable
            disableToolbar
            format='dd MMM yyyy'
            name='submitDateTo'
            label='Submit Date To'
            value={filter['submitDateTo'] || null}
            onChange={(e: MaterialUiPickersDate) => {
              handleFilterDateChange('submitDateTo', e)
            }}
          />
          <DatePicker
            clearable
            disableToolbar
            format='dd MMM yyyy'
            name='activatedDateFrom'
            label='Activated Date From'
            value={filter['activatedDateFrom'] || null}
            onChange={(e: MaterialUiPickersDate) => {
              handleFilterDateChange('activatedDateFrom', e)
            }}
          />
          <DatePicker
            clearable
            disableToolbar
            format='dd MMM yyyy'
            name='activatedDateTo'
            label='Activated Date To'
            value={filter['activatedDateTo'] || null}
            onChange={(e: MaterialUiPickersDate) => {
              handleFilterDateChange('activatedDateTo', e)
            }}
          />
          <Autocomplete
            className='col-span-4'
            renderInput={params => (
              <TextField
                {...params}
                label='Agent'
                inputProps={{ ...params.inputProps }}
              />
            )}
            getOptionLabel={option => {
              const prefix = CommonUtils.getUserCode(option, userCode)
              const { fullName, username, id } = option

              const displayName =
                option.companyName && option.companyRegNo
                  ? `${option.companyName} (${option.companyRegNo})`
                  : `${fullName} (${username})`

              const suffix =
                userProfileService.getUserProfile()?.id === id
                  ? '[YOURSELF]'
                  : ''

              return `[${prefix}] ${displayName} ${suffix}`
            }}
            options={Object.values(agentMap)}
            value={agentMap[filter.agent] || null}
            onChange={(event, value) => {
              if (value) {
                handleFilterChange('agent', value.username)
              } else {
                handleFilterChange('agent', '')
              }
            }}
          />

          <FormControl component='fieldset' fullWidth className='col-span-4'>
            <FormLabel component='legend' id='orderStatus'>
              Show Order:
            </FormLabel>

            <RadioGroup
              row
              aria-label='transactionType'
              name='transactionType'
              value={filter.orderStatus}
              onChange={e => {
                handleFilterChange('orderStatus', e.target.value)
              }}
            >
              <FormControlLabel
                value={filterOrderStatus.IN_PROGRESS}
                control={<Radio />}
                label={_.startCase(filterOrderStatus.IN_PROGRESS)}
              />
              <FormControlLabel
                value={filterOrderStatus.COMPLETED}
                control={<Radio />}
                label={_.startCase(filterOrderStatus.COMPLETED)}
              />
              <FormControlLabel
                value={filterOrderStatus.ALL}
                control={<Radio />}
                label={_.startCase(filterOrderStatus.ALL)}
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
        <CardActions className='justify-end'>
          <Button variant='outlined' onClick={handleFilterClear}>
            Clear
          </Button>
        </CardActions>
      </Card>
      <div className='m-8 bg-white'>
        <Alert severity='info'>
          <span className='font-bold'>Double click</span> on order to view order
          detail.
        </Alert>
        <div className='m-4'>
          <TextField
            name='filterString'
            value={filterString}
            onChange={e => {
              setFilterString(e.target.value)
              setDebounceString(e.target.value)
            }}
            fullWidth
            placeholder='Type something to filter...'
          />
        </div>
        <TablePagination
          rowsPerPageOptions={rowPerPageOptions}
          component='div'
          count={filteredOrder.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {orderDisplayList.map(text => (
                  <TableCell key={text} id={text}>
                    {_.startCase(text)}
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            {filterString !== debounceString ? (
              <CircularProgress />
            ) : (
              <TableBody>
                {filteredOrder
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(value => renderOrder2(value))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowPerPageOptions}
          component='div'
          count={filteredOrder.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  )
}

export default OrderManagementPage
