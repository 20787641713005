import userProfileService from '../../common/service/userProfileService'
import UserBean from '../../sharePortal/userManagement/bean/userBean'

const initialState: UserBean = userProfileService.getUserProfile() || {
  address: '',
  bankAccNo: '',
  bankName: '',
  username: '',
  city: '',
  country: '',
  email: '',
  enabled: false,
  fullName: '',
  id: 0,
  mobileNo: '',
  nric: '',
  postcode: '',
  role: '',
  state: '',
  telNo: '',
}

interface UserProfileReducerAction {
  type: string
  payload: UserBean
}

const userProfileReducer = (
  state = initialState,
  action: UserProfileReducerAction
) => {
  switch (action.type) {
    case UserProfileType.LOGIN_SUCCESS:
      return {
        ...action.payload,
      }
    default:
      return state
  }
}

export const UserProfileType = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
}
export default userProfileReducer
