import React, { useCallback, useEffect, useState } from 'react'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ClaimService from './service/claimService'
import { ClaimBean } from './bean/claimBean'
import { AxiosError } from 'axios'
import { ErrorResponse } from '../../errorResponse'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import WithdrawalService from './service/withdrawalService'
import { WithdrawalBean } from './bean/WithdrawalBean'
import ClaimStatus from '../../common/constant/claimStatus'
import userProfileService from '../../common/service/userProfileService'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import PrintIcon from '@material-ui/icons/Print'
import PaymentVoucherService from './service/paymentVoucherService'
import FileSaver from 'file-saver'
import TransactionType from '../../common/constant/transactionType'
import { Alert } from '@material-ui/lab'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import WithdrawalTableComponent from './component/withdrawalTableComponent'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import DeleteIcon from '@material-ui/icons/Delete'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import AddWithdrawalDialog from './component/addWithdrawal.dialog'
import GetAppIcon from '@material-ui/icons/GetApp'
import { exportWithdrawalsToCsv } from '../../adminPortal/withdrawals/withdrawal.utils'
import _ from 'lodash'
import { useTypedSelector } from '../../redux/reducer'

interface ClaimDetailItem {
  amount: number
  icon: React.ReactNode
  color: string
  desc: string
}

interface ClaimFormState {
  claimStatus: String
  referenceNo: String
  remarks: String
}

const RenderClaimInfo = (props: { claim?: ClaimBean }) => {
  const { claim } = props
  if (!claim) return null
  return (
    <Card className='m-8 rounded shadow '>
      <CardHeader title={`Claim - #${claim?.id}`} />
      <CardContent className='grid grid-cols-2 gap-2'>
        <div>
          <span className='font-light text-gray-800 mr-2'>Agent Name:</span>
          <span className='text-lg'>
            {(function (user: UserBean) {
              return user.companyName && user.companyRegNo
                ? `${user.companyName} (${user.companyRegNo})`
                : user.fullName
            })(claim.refUserEntity)}
          </span>
        </div>
        <div>
          <span className='font-light text-gray-800 mr-2'>Status:</span>
          <span className='text-sm text-white border-none bg-blue-400 border rounded p-1'>
            {claim.claimStatus}
          </span>
        </div>

        <div>
          <span className='font-light text-gray-800 mr-2'>Agent Email:</span>
          <span className='text-lg'>{claim.refUserEntity.email}</span>
        </div>
        <div>
          <span className='font-light text-gray-800 mr-2'>IC:</span>
          <span className='text-lg'>{claim.refUserEntity.nric}</span>
        </div>

        <div>
          <span className='font-light text-gray-800 mr-2'>Bank Account:</span>
          <span className='text-lg'>{claim.refUserEntity.bankAccNo}</span>
        </div>
        <div>
          <span className='font-light text-gray-800 mr-2'>Bank Name:</span>
          <span className='text-lg'>{claim.refUserEntity.bankName}</span>
        </div>
        <div>
          <span className='font-light text-gray-800 mr-2'>Bank Beneficial Name:</span>
          <span className='text-lg'>{claim.refUserEntity.bankBeneficialName}</span>
        </div>
      </CardContent>
    </Card>
  )
}
const ClaimDetailPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { claimId } = useParams()

  const [openDialog, setOpenDialog] = useState(false)
  const [selectedFile, setSelectedFile] = React.useState<File>()
  const [claim, setClaim] = useState<ClaimBean>()
  const [withdrawals, setWithdrawals] = useState<WithdrawalBean[]>([])
  const [claimDetailItems, setClaimDetailItems] = useState<ClaimDetailItem[]>(
    []
  )
  const [claimableAmount, setClaimableAmount] = useState<number>(0)
  const [claimFormState, setClaimFormState] = useState<ClaimFormState>({
    claimStatus: '',
    referenceNo: '',
    remarks: '',
  })

  const [isDisableEdit, setIsDisableEdit] = useState(false)

  useEffect(() => {
    ClaimService.findById(claimId)
      .then(value => {
        setClaim(value.data)
        setClaimFormState({
          remarks: value.data.remarks || '',
          claimStatus: value.data.claimStatus,
          referenceNo: value.data.referenceNo || '',
        })

        setIsDisableEdit(
          [ClaimStatus.COMPLETED, ClaimStatus.REJECTED].some(
            status => value.data.claimStatus === status
          )
        )
      })
      .catch((reason: AxiosError<ErrorResponse>) => {
        catchErrorWithDispatch(dispatch)(reason)
        if (reason.response?.status === 403) {
          history.goBack()
          return
        }
      })
  }, [claimId, dispatch, history])

  const getWithdrawal = useCallback(() => {
    WithdrawalService.findAll({ search: `claimId:${claimId}` })
      .then(value => {
        setWithdrawals(value.data)

        const detailItems = generateClaimDetailItems(value.data)
        setClaimDetailItems(detailItems)

        const amount = value.data
          .map(a => a.amount)
          .reduce((pre, cur) => pre + cur, 0)
        setClaimableAmount(amount)
      })
      .catch(catchErrorWithDispatch(dispatch))
  }, [claimId, dispatch])
  useEffect(() => {
    getWithdrawal()
  }, [claimId, dispatch, getWithdrawal])

  function generateClaimDetailItems(
    withdrawals: WithdrawalBean[]
  ): ClaimDetailItem[] {
    const claimedAmount: ClaimDetailItem = {
      amount: Number(
        withdrawals
          .filter(value =>
            [TransactionType.SELF, TransactionType.AGENT].includes(
              value.transactionType
            )
          )
          .map(value => value.amount)
          .reduce((pre, cur) => pre + cur, 0)
      ),
      icon: <LocalAtmIcon className='text-white' />,
      color: 'teal',
      desc: 'Claimed Amount',
    }

    const incentives: ClaimDetailItem = {
      amount: Number(
        withdrawals
          .filter(value =>
            [TransactionType.INCENTIVES].includes(value.transactionType)
          )
          .map(value => value.amount)
          .reduce((pre, cur) => pre + cur, 0)
      ),
      icon: <AddIcon className='text-white' />,
      color: 'blue',
      desc: 'Incentives',
    }

    const amountDeduct: ClaimDetailItem = {
      amount: Number(
        withdrawals
          .filter(value =>
            [TransactionType.CHARGES, TransactionType.CLAWBACK].includes(
              value.transactionType
            )
          )
          .map(value => value.amount)
          .reduce((pre, cur) => pre + cur, 0)
      ),
      icon: <RemoveIcon className='text-white' />,
      color: 'orange',
      desc: 'Amount Deduct',
    }

    return [claimedAmount, incentives, amountDeduct]
  }

  const handleSubmitClick = () => {
    if (claimFormState || !!selectedFile) {
      dispatch(LoadingAction.open('Updating Claim...'))

      const promises = []
      if (claimFormState) {
        promises.push(ClaimService.patchClaim(claimId, { ...claimFormState }))
      }
      if (selectedFile) {
        promises.push(ClaimService.addAttachment(claimId, selectedFile))
      }

      Promise.all(promises)
        .then(() => {
          dispatch(SnackbarAction.open('Success Update Claim'))
          history.goBack()
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    } else {
      alert('SOMETHING WRONG, YOU SHOULD NOT SEE THIS...')
    }
  }

  function onPrintClick() {
    if (claimId)
      PaymentVoucherService.voucherByClaimId(claimId)
        .then(value => {
          const contentDisposition =
            value.headers['content-disposition'].toString()
          contentDisposition.replace('attachment;', '')
          const fileName = contentDisposition.split('=')[1]
          console.log(fileName)

          FileSaver.saveAs(value.data, fileName)
        })

        .catch(catchErrorWithDispatch(dispatch))
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null
    const maxSize5Mb = 1024 * 5 * 1000
    if (file) {
      const strings = file.name.split('.')
      if (strings.reverse()[0].toLowerCase() !== 'pdf') {
        dispatch(
          AlertDialogAction.open('Invalid File', 'This file is not PDF format.')
        )
        event.target.value = ''
        return
      }
      if (file.size > maxSize5Mb) {
        dispatch(
          AlertDialogAction.open(
            'File Too Big',
            'Your selected file is bigger than 5MB'
          )
        )
        event.target.value = ''
        return
      }

      setSelectedFile(file)
    } else {
      setSelectedFile(undefined)
    }
  }

  const requiredInvoiceNo = useTypedSelector(
    state => state.claimSettings.requiredInvoiceNoToCompleteClaim
  )

  const disableClaimButton = () => {
    // Check portal setting is enable this checking.
    if (!requiredInvoiceNo) {
      return false
    } else if (!!withdrawals) {
      const changeToCompleted =
        claimFormState.claimStatus === ClaimStatus.COMPLETED
      const allWithdrawalInvoiceNoNotEmpty = withdrawals
        .filter(o => !_.isNil(o.refOrderEntity))
        .every(o => (o?.refOrderEntity?.invoiceNo || '') !== '')

      return changeToCompleted && !allWithdrawalInvoiceNoNotEmpty
    } else {
      return false
    }
  }

  function exportClaimToCsv() {
    exportWithdrawalsToCsv(
      withdrawals,
      `Payment_Voucher_${claimId}`,
      'Payment Voucher'
    )
  }

  const nothingChanged = () =>
    !selectedFile &&
    claimFormState.claimStatus === (claim?.claimStatus || '') &&
    claimFormState.referenceNo === (claim?.referenceNo || '') &&
    claimFormState.remarks === (claim?.remarks || '')

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3'>
          <IconButton color='inherit' onClick={history.goBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant='h6'>Claim Detail</Typography>

          <Tooltip title={'Download CSV File'}>
            <Button
              variant={'outlined'}
              color='inherit'
              onClick={() => exportClaimToCsv()}
              startIcon={<GetAppIcon />}
            >
              Download CSV
            </Button>
          </Tooltip>

          {!isDisableEdit &&
            (userProfileService.isAdmin() ||
              userProfileService.isManager()) && (
              <Tooltip title={'Add Withdrawal'}>
                <IconButton color='inherit' onClick={() => setOpenDialog(true)}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}

          {[ClaimStatus.COMPLETED, ClaimStatus.PAID].includes(
            claim?.claimStatus ?? ''
          ) && (
            <Tooltip title={'Print Payment Voucher'}>
              <IconButton color='inherit' onClick={onPrintClick}>
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={'Delete Claim'}>
            <IconButton
              color='inherit'
              disabled={claim?.claimStatus === ClaimStatus.COMPLETED}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()

                dispatch(
                  AlertDialogAction.open(
                    <>
                      Delete{' '}
                      <span className='font-bold'>Claim #{claim?.id}</span>
                    </>,
                    <>
                      Are you sure to delete{' '}
                      <span className='font-bold'>Claim #{claim?.id}</span> and
                      release withdrawal id{' '}
                      <span className='font-bold'>
                        {withdrawals
                          .map(value => value.id)
                          .sort()
                          .join(', ')}
                      </span>
                    </>,
                    () => {
                      dispatch(LoadingAction.open('Deleting Claim...'))

                      ClaimService.delete(claimId)
                        .then(() => {
                          dispatch(SnackbarAction.open('Success Delete Claim'))
                          history.goBack()
                        })

                        .catch(catchErrorWithDispatch(dispatch))
                        .finally(() => dispatch(LoadingAction.close()))
                    }
                  )
                )
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <div className='m-8 flex'>
        <div className='flex-1 flex'>
          {claimDetailItems.map(item => {
            const { amount, icon, color, desc } = item
            return (
              <div
                key={item.desc + item.amount}
                className='flex items-center mx-2'
              >
                <div className={`m-2 p-2 bg-${color}-400 rounded-full`}>
                  {icon}
                </div>
                <div>
                  <div className='text-gray-700 text-2xl'>
                    RM {amount.toFixed(2)}
                  </div>
                  <div>
                    <span className='text-gray-500 text-sm'>{desc}</span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className='text-right'>
          <div className='text-gray-800'>CLAIMABLE AMOUNT</div>
          <div className='text-xl text-blue-600'>
            RM {claimableAmount.toFixed(2)}
          </div>
        </div>
      </div>

      <RenderClaimInfo claim={claim} />

      <div className='m-8'>
        <WithdrawalTableComponent
          withdrawals={withdrawals}
          successEdit={() => {
            getWithdrawal()
          }}
          canEditClaims={[ClaimStatus.PROCESSING, ClaimStatus.PENDING].includes(
            claim?.claimStatus || ''
          )}
        />
      </div>

      <Card className={'m-8'}>
        <CardHeader title={'Claim Status'} />
        <CardContent className='space-y-3'>
          <FormControl required fullWidth>
            <Select
              disabled={userProfileService.isAgent() || isDisableEdit}
              id='claimStatus'
              name='claimStatus'
              value={claimFormState.claimStatus}
              onChange={(
                e: React.ChangeEvent<{ name?: string; value: any }>
              ) => {
                setClaimFormState({
                  ...claimFormState,
                  claimStatus: e.target.value,
                })
              }}
            >
              {Object.keys(ClaimStatus.toDisplay).map(status => (
                <MenuItem key={status} value={status}>
                  {ClaimStatus.toDisplay[status]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label='Reference No'
            name='referenceNo'
            value={claimFormState.referenceNo}
            disabled={userProfileService.isAgent() || isDisableEdit}
            fullWidth
            onChange={event => {
              setClaimFormState({
                ...claimFormState,
                referenceNo: event.target.value,
              })
            }}
          />

          <TextField
            multiline
            rows={3}
            label='Remarks'
            name='remarks'
            fullWidth
            disabled={userProfileService.isAgent() || isDisableEdit}
            value={claimFormState.remarks}
            onChange={event => {
              setClaimFormState({
                ...claimFormState,
                remarks: event.target.value,
              })
            }}
          />

          {!userProfileService.isAgent() && (
            <>
              <Alert severity='info'>
                Upload PDF Attachment smaller than 5MB only.
              </Alert>

              <input
                type={'file'}
                disabled={userProfileService.isAgent()}
                onChange={handleFileChange}
              />
            </>
          )}

          {!userProfileService.isAgent() && claim?.attachmentUrl && (
            <div>
              <a
                href={claim?.attachmentUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Button color={'primary'} variant={'contained'}>
                  Download Attachment
                </Button>
              </a>
            </div>
          )}
        </CardContent>
        {!userProfileService.isAgent() && (
          <CardActions className='justify-end'>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={handleSubmitClick}
              disabled={nothingChanged() || disableClaimButton()}
            >
              SUBMIT
            </Button>
          </CardActions>
        )}
      </Card>

      <AddWithdrawalDialog
        open={openDialog}
        claimId={claimId}
        agentId={claim?.agentId}
        onClose={() => setOpenDialog(false)}
        onSuccess={getWithdrawal}
      />
    </div>
  )
}

export default ClaimDetailPage
