import React, { useEffect } from 'react'
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import Dashboard from './dashboard/dashboard'
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/People'
import DashboardIcon from '@material-ui/icons/Dashboard'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import CompletedOrderPage from './orderManagement/completedOrderPage'
import SubmitClaimPage from '../features/claim/submitClaimPage'
import DebitNotePage from '../features/claim/debitNotePage'
import ProductPage from '../adminPortal/productManagement/productPage'
import ProductCategoryPage from '../adminPortal/productCategoryManagement/productCategoryPage'
import ProductPackagePage from '../adminPortal/productPackageManagement/productPackagePage'
import AuditPage from '../adminPortal/audit/auditPage'
import NavListItem from '../common/component/navListItem'
import CommissionManagementPage from '../adminPortal/userManagement/commissionManagementPage'
import EnquiryPage from './enquiry/enquiryPage'
import ClaimDetailPage from '../features/claim/claimDetailPage'
import OrderDetailPage from '../sharePortal/orderManagement/orderDetailPage'
import OrderManagementPage from '../sharePortal/orderManagement/orderManagementPage'
import { PAGE, ROUTES } from '../common/constant/pageKeyConstant'
import ProfileDetailPage from '../sharePortal/userManagement/profileDetailPage'
import NewOrderPage from './orderManagement/newOrderPage'
import AgentDetailPage from '../adminPortal/userManagement/agentDetailPage'
import InvitationPage from './invitation/invitationPage'
import { PortalSettingActions } from '../redux/reducer/portalSettingReducer'
import { useDispatch } from 'react-redux'
import SearchClaimPage from '../features/claim/searchClaimPage'
import { getAllOrderSummaryMessage } from '../redux/slice/orderSummaryMessageSlice'
import {
  getDefaultCreatedOrderStatus,
  getOrderDisplayList,
} from '../redux/slice/orderSettingsSlice'
import ClaimPageV2 from '../features/claim/claimPageV2'
import TemplatePage from '../features/template/template.page'
import { TemplateCreatePage } from '../features/template/templateCreate.page'
import TemplateDetailPage from '../features/template/templateDetail.page'
import SettingsIcon from '@material-ui/icons/Settings'
import AgentStaffPage from '../features/agentStaff/agentStaff.page'
import AgentStaffCreatePage from '../features/agentStaff/agentStaffCreate.page'
import userProfileService from '../common/service/userProfileService'
import AppNavContainer from '../AppNavContainer'
import UserSettingPage from '../common/userSetting/userSettingPage'

const AgentPortalSwitch = () => {
  const routeMatch = useRouteMatch()

  return (
    <Switch>
      <Route path={`${routeMatch.path}/dashboard`}>
        <Dashboard />
      </Route>
      <Route path={`${routeMatch.path}/new-order`}>
        <NewOrderPage />
      </Route>
      <Route path={`${routeMatch.path}/orders`}>
        <OrderManagementPage />
      </Route>
      <Route path={`${routeMatch.path}/order-detail/:id`}>
        <OrderDetailPage />
      </Route>
      <Route path={`${routeMatch.path}/completed-orders`}>
        <CompletedOrderPage />
      </Route>
      <Route path={`${routeMatch.path}/agent-commission`}>
        <CommissionManagementPage />
      </Route>

      <Route path={`${routeMatch.path}/invitation-link`}>
        <InvitationPage />
      </Route>
      <Route path={`${routeMatch.path}/claims`}>
        <ClaimPageV2 />
      </Route>
      <Route path={`${routeMatch.path}/view-claim/:claimId`}>
        <ClaimDetailPage />
      </Route>
      <Route path={`${routeMatch.path}/submit-claim`}>
        <SubmitClaimPage />
      </Route>
      <Route path={`${routeMatch.path}/debit-note`}>
        <DebitNotePage />
      </Route>
      <Route path={`${routeMatch.path}/product`}>
        <ProductPage />
      </Route>
      <Route path={`${routeMatch.path}/product-category/:productId`}>
        <ProductCategoryPage />
      </Route>
      <Route path={`${routeMatch.path}/product-package/:categoryId`}>
        <ProductPackagePage />
      </Route>
      <Route path={`${routeMatch.path}/audit`}>
        <AuditPage />
      </Route>
      <Route path={`${routeMatch.path}/enquiry`}>
        <EnquiryPage />
      </Route>
      <Route path={`${routeMatch.path}/profile-detail`}>
        <ProfileDetailPage />
      </Route>
      <Route path={`${routeMatch.path}/agent-detail/:id`}>
        <AgentDetailPage />
      </Route>

      <Route path={`${routeMatch.path}/search-claim-and-withdrawal`}>
        <SearchClaimPage />
      </Route>
      <Route path={`${routeMatch.path}/templates`} exact>
        <TemplatePage />
      </Route>

      <Route path={`${routeMatch.path}/templates/create`} exact>
        <TemplateCreatePage />
      </Route>
      <Route path={`${routeMatch.path}/templates/:id`} exact>
        <TemplateDetailPage />
      </Route>
      <Route path={`${routeMatch.path}/${ROUTES.agentStaffListing()}`} exact>
        <AgentStaffPage />
      </Route>
      <Route path={`${routeMatch.path}/${ROUTES.agentStaffCreate()}`} exact>
        <AgentStaffCreatePage />
      </Route>
      <Route path={`${routeMatch.path}/${ROUTES.userSetting()}`} exact>
        <UserSettingPage />
      </Route>

      <Route path={routeMatch.path}>
        <Redirect to={`${routeMatch.path}/dashboard`} />
        Hello World
      </Route>
    </Switch>
  )
}
const AgentPortalNavList = () => {
  const routeMatch = useRouteMatch()

  return (
    <>
      <NavLink
        to={routeMatch.path + '/dashboard'}
        activeClassName='text-indigo-600 italic'
      >
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={'Dashboard'} />
        </ListItem>
      </NavLink>

      <Divider />

      <NavListItem
        title='Order Management'
        icon={<InboxIcon />}
        navItems={[
          {
            key: PAGE.NewOrderPage,
            name: 'New Order',
            url: routeMatch.path + '/new-order',
          },
          {
            key: PAGE.OrderDetailPage,
            name: 'View Orders',
            url: routeMatch.path + '/orders',
          },
          // {
          //   name: 'View Completed Orders',
          //   url: routeMatch.path + '/completed-orders',
          // },
        ]}
      />

      <Divider />

      <NavListItem
        title='Agent Management'
        icon={<AssignmentIndIcon />}
        navItems={[
          {
            key: PAGE.AgentCommissionsPage,
            name: 'Agent Commissions',
            url: routeMatch.path + '/agent-commission',
          },
          {
            key: PAGE.InvitationPage,
            name: 'Invitation',
            url: routeMatch.path + '/invitation-link',
          },
        ]}
      />

      <Divider />

      {userProfileService.isTopAgent() && (
        <>
          <NavListItem
            title='Staff Management'
            icon={<PeopleIcon />}
            navItems={[
              {
                key: PAGE.AgentStaffPage,
                name: 'Staff',
                url: routeMatch.path + '/' + ROUTES.agentStaffListing(),
              },
            ]}
          />

          <Divider />
        </>
      )}

      <NavListItem
        title='Claim Management'
        icon={<AttachMoneyIcon />}
        navItems={[
          {
            key: PAGE.ClaimPage,
            name: 'View Claim',
            url: routeMatch.path + '/claims',
          },
          {
            key: PAGE.SubmitClaimPage,
            name: 'Submit Claim',
            url: routeMatch.path + '/submit-claim',
          },

          {
            key: PAGE.SearchClaimPage,
            name: 'Search Claim and Withdrawal',
            url: routeMatch.path + '/search-claim-and-withdrawal',
          },

          // {
          //   name: 'Debit Note',
          //   url: routeMatch.path + '/debit-note',
          // },
        ]}
      />

      <NavListItem
        title='Settings Management'
        icon={<SettingsIcon />}
        navItems={[
          {
            key: PAGE.TemplatePage,
            name: 'Template Page',
            url: routeMatch.path + '/templates',
          },
          {
            key: PAGE.UserSettingPage,
            name: 'User Setting Page',
            url: routeMatch.path + '/' + ROUTES.userSetting(),
          },
        ]}
      />
    </>
  )
}

const AgentPortalIndex = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(PortalSettingActions.getUserCode())
    dispatch(PortalSettingActions.getPackageShowDescription())
    dispatch(PortalSettingActions.getOrderSummarySetting())
    dispatch(PortalSettingActions.getOrderShowCommissionRate())
    dispatch(PortalSettingActions.getRoleAbleToViewCommissionRate())
    dispatch(getAllOrderSummaryMessage())
    dispatch(getOrderDisplayList())
    dispatch(getDefaultCreatedOrderStatus())
  }, [dispatch])

  return (
    <AppNavContainer
      navList={<AgentPortalNavList />}
      switch={<AgentPortalSwitch />}
    />
  )
}

AgentPortalIndex.propTypes = {}

export default AgentPortalIndex
