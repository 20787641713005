import UserBean from '../../userManagement/bean/userBean'

export interface OrderBean {
  id: number
  agentId: number
  product: string
  productCategory: string
  productPackage: string
  productDescription?: string
  productId: string
  productCategoryId: string
  productPackageId: string
  price: number
  companyName: string
  companyRegNo: string
  address: string
  postcode: string
  state: string
  fullName: string
  nric: string
  email: string
  mobileNo: string
  telNo?: string
  orderStatus: string
  remarks?: string
  orderNo?: string
  loginId?: string
  eform?: string
  documentCompleted: boolean
  documentUrl?: string
  activatedOn?: string
  createdDate: string
  createdBy: string
  modifiedDate: string
  modifiedBy: string
  refUser: UserBean
  invoiceNo?: string
  clawbackDate?: Date

  unitNo: string
  streetType: string
  streetName: string
  section: string
  floorNo: string
  buildingName: string
  city: string

  alternativeNo?: string
  adminRemarks?: string
  m2uRemarks?: string

  paymentClaimDate?: Date
  processingId?: string
  accountNo?: string
  submittedDate?: Date
  statusChangeReason?: string
  sporaReferenceNumber?: string
  ociTicketNumber?: string

  sporaProcessingId?: string
  appointmentDate?: string
  appointmentTime?: string
}

export const demoOrder: OrderBean = {
  id: 1108,
  agentId: 3,
  product: 'TM',
  productCategory: 'UNIFIHOME(NEW)',
  productPackage: 'unifi 30Mbps - Try Me Broadband',
  productId: '1',
  productCategoryId: '1',
  productPackageId: '1',
  price: 89,
  companyName: 'COMPANY_NAME',
  companyRegNo: 'COMPANY_REG_NUMBER',
  address: '',
  postcode: '99999',
  state: 'Johor',
  fullName: 'Lula Konopelski',
  nric: '889759-55-2490',
  email: 'Vella.Nitzsche@yahoo.com',
  mobileNo: '012-3592912',
  telNo: '546663960',
  orderStatus: 'AGENT_SUBMITTED',
  remarks: 'REMARKS',
  orderNo: 'f84aa7ae-8eb9-412a-b2c0-5c71789f948f',
  loginId: 'Valentin2',
  documentCompleted: false,
  documentUrl: undefined,
  activatedOn: '2021-01-01T00:00:00',
  createdDate: '2021-01-03T13:51:01',
  createdBy: 'superuser',
  modifiedDate: '2021-01-03T13:51:01',
  modifiedBy: 'superuser',
  refUser: {
    id: 3,
    fullName: 'AMIR ASYRAAF BIN ABD AZIZ',
    username: 'amir',
    role: 'ROLE_AGENT',
    enabled: true,
    email: 'amirasyraaf.gam@gmail.com',
    nric: '951228105487',
    companyName: '',
    companyRegNo: '',
    address: '10 Jalan Putra Perdana 6/6 Taman Putra Perdana',
    city: 'Puchong',
    state: 'Selangor',
    postcode: '47130',
    country: 'Malaysia',
    mobileNo: '0176874058',
    telNo: '',
    bankName: 'Maybank',
    bankAccNo: '168603061362',
    superiorId: undefined,
  },
  invoiceNo: 'REFERENCE_INVOICE_NO',
  clawbackDate: undefined,
  unitNo: '26',
  streetType: 'Mills',
  streetName: '219 Langworth Hollow',
  section: 'Franecki Fork',
  floorNo: '71',
  buildingName: 'BUILDING_NAME',
  city: 'Aldashire',
  alternativeNo: '099-888888',
  adminRemarks: 'ADMIN_REMARKS',
  m2uRemarks: '',
  paymentClaimDate: undefined,
  processingId: 'PROCESSING_ID',
  accountNo: 'ACCOUNT_NO',
  eform: 'ffa793a0-67f5-4e1d-819c-319b0ed8a10b',
  statusChangeReason: 'Change Reason',
  sporaReferenceNumber: 'sporaReferenceNumber',
  ociTicketNumber: 'ociTicketNumber',
  sporaProcessingId: 'sporaProcessingId',
  appointmentDate: '2020-12-31',
  appointmentTime: 'MORNING',
  productDescription: "PRODUCT DESCRIPTION",
  submittedDate: new Date("2022-04-13T21:21:38")
}
