import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthenticationService } from './service/authenticationService'
import * as faker from 'faker'
import { useDispatch } from 'react-redux'
import { LoadingType } from '../redux/reducer/loadingBackdropReducer'
import CommonUtils from '../common/commonUtils'
import { AlertDialogType } from '../redux/reducer/alertDialogReducer'
import { StateBean } from '../common/service/StateBean'
import { BankBean } from '../common/service/BankBean'
import CommonApiService from '../common/service/CommonApiService'
import qs from 'qs'
import { AgentRegisterPostBody } from './bean/agentRegisterPostBody'
import { RegexUtils } from '../common/regexUtils'
import { catchErrorWithDispatch } from '../common/ApiUtils'
import { SnackbarAction } from '../redux/reducer/snackbarReducer'
import { OrderUploadFileKey } from '../common/orderUtils'
import { UserProfileAttachemntService } from './service/userProfileAttachemntService'
import UserProfileAttachmentTable from '../common/component/userProfileAttachmentTable'

const SignUpPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const [inviteUsername, setInviteUsername] = useState<string>()
  const [failDecodeInviteUsername, setFailDecodeInviteUsername] = useState(
    false
  )
  const [statess, setStatess] = useState<StateBean[]>([])
  const [banks, setBanks] = useState<BankBean[]>([])
  const [files, setFiles] = useState<Record<string, File[]>>({})

  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: '',
  })

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarOpen({ message: '', open: false })
  }

  const initialState = {
    username: '',
    password: '',
    fullName: '',
    nric: '',
    email: '',
    mobileNo: '',
    bankName: '',
    bankAccNo: '',
    bankBeneficialName: '',
    address: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    companyName: '',
    companyRegNo: '',
    telNo: '',
    superiorUsername: '',
  }
  const [formState, setFormState] = useState<AgentRegisterPostBody>(
    initialState
  )
  const [errorState, setErrorState] = useState<AgentRegisterPostBody>(
    initialState
  )

  useEffect(() => {
    if (CommonUtils.isDevMode()) {
      setFormState({
        username: faker.internet.userName(),
        password: 'password',
        fullName: faker.name.findName(),
        nric: faker.random.number().toString(),
        email: 'kevin999990@yahoo.com',
        mobileNo: faker.phone.phoneNumber('01#-#######'),
        bankName: 'Maybank',
        bankAccNo: faker.finance.account(),
        bankBeneficialName: faker.name.findName(),
        address: faker.address.streetAddress(),
        city: faker.address.city(),
        state: 'Johor',
        postcode: faker.phone.phoneNumber('#####'),
        country: faker.address.country(),
        companyName: '',
        companyRegNo: '',
        telNo: '',
        superiorUsername: '',
      })
    }
  }, [])

  useEffect(() => {
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (token) {
      try {
        const inviteUsername = atob(token?.toString() || '')
        if (inviteUsername) {
          setInviteUsername(inviteUsername)
        }
      } catch (e) {
        setFailDecodeInviteUsername(true)
      }
    }
  }, [failDecodeInviteUsername, history, location])

  useEffect(() => {
    CommonApiService.getBanks().then(value => setBanks(value.data))

    CommonApiService.getStates().then(value => setStatess(value.data))
  }, [])

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (Object.values(errorState).filter(v => !!v).length) {
      dispatch(SnackbarAction.openError('Form Error, Please check.'))
      return
    }

    if (!files[OrderUploadFileKey.MY_KAD]) {
      dispatch(SnackbarAction.openError('Missing MyKad, please upload.'))
      return
    }

    dispatch({
      type: LoadingType.TOGGLE_LOADING,
      payload: 'Submitting...',
    })
    const postBody: AgentRegisterPostBody = {
      ...formState,
    }
    if (inviteUsername) {
      postBody.superiorUsername = inviteUsername
    }

    AuthenticationService.register(postBody)
      .then(async value => {
        const userId = value.data.id

        await Promise.all(
          Object.entries(files).map(entry => {
            const [category, file] = entry
            return UserProfileAttachemntService.createUserProfileAttachment(
              userId,
              file,
              category
            )
          })
        )
        dispatch({
          type: AlertDialogType.TOGGLE_OPEN,
          payload: {
            title: 'Success Register',
            message: 'Please check your email to verify your account.',
          },
        })

        history.push('/')
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => {
        dispatch({
          type: LoadingType.TOGGLE_LOADING,
        })
      })

    event.preventDefault()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const trim = value.trim()

    switch (name) {
      case 'username':
        setFormState({
          ...formState,
          [name]: trim,
        })

        if (!RegexUtils.usernameRegex.test(trim)) {
          setErrorState({
            ...errorState,
            username:
              'Minimum 8 character, Only Use [A-Z], [a-z], [0-9], and [.], [_] in between Alphanumeric',
          })
        } else {
          setErrorState({
            ...errorState,
            username: '',
          })
        }

        break
      case 'mobileNo':
        setFormState({
          ...formState,
          [name]: trim,
        })

        if (!RegexUtils.phoneNumberRegex.test(trim)) {
          setErrorState({
            ...errorState,
            [name]: 'Mobile Number Wrong Format',
          })
        } else {
          setErrorState({
            ...errorState,
            [name]: '',
          })
        }

        break
      case 'postcode':
        setFormState({
          ...formState,
          [name]: trim,
        })

        if (!RegExp('[0-9]{5}').test(trim)) {
          setErrorState({
            ...errorState,
            [name]: 'Postcode Wrong Format',
          })
        } else {
          setErrorState({
            ...errorState,
            [name]: '',
          })
        }
        break
      default:
        setFormState({
          ...formState,
          [name]: value,
        })
        break
    }
  }

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    if (event.target.name) {
      setFormState({
        ...formState,
        [event.target.name]: event.target.value,
      })
    }
  }
  if (failDecodeInviteUsername) {
    return (
      <div className='bg-gray-300 min-h-screen p-10'>
        <Container className='m-4'>
          <Card>
            <CardHeader title={'Invalid Token'} />
            <CardContent>
              You token is invalid, please check your received link.
            </CardContent>
            <CardActions>
              <Button
                onClick={() => history.replace('/')}
                fullWidth
                color={'secondary'}
              >
                Back
              </Button>
            </CardActions>
          </Card>
        </Container>
      </div>
    )
  } else
    return (
      <div className='bg-gray-300 min-h-screen p-10'>
        <Container className='m-4'>
          {inviteUsername && (
            <Card className='my-4 p-4 text-center'>
              <span>Your are invited by </span>
              <span className='font-bold'>{inviteUsername}</span>
            </Card>
          )}

          <Card>
            <CardContent>
              <Typography variant='h4' className='text-center'>
                Registration
              </Typography>

              <form onSubmit={onSubmitForm}>
                <div>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['username']}
                        required
                        id='username'
                        name='username'
                        label='Username'
                        helperText={
                          errorState['username'] || `Your login username`
                        }
                        error={!!errorState['username']}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['password']}
                        required
                        id='password'
                        name='password'
                        label='Password'
                        helperText='Your login password'
                        type='password'
                        fullWidth
                      />
                    </Grid>
                    <hr />
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['fullName']}
                        required
                        id='fullName'
                        name='fullName'
                        label='Full Name'
                        helperText='Name as NRIC'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['nric']}
                        required
                        id='nric'
                        name='nric'
                        label='NRIC'
                        helperText={`(${formState['nric'].length}/14) Please enter nric number WITH '-'`}
                        placeholder='Eg: 830101-07-1234'
                        inputProps={{ maxLength: 14 }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['email']}
                        required
                        id='email'
                        name='email'
                        label='Email'
                        helperText='Please use actual email, we will sent you activation code'
                        type='email'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['mobileNo']}
                        error={!!errorState['mobileNo']}
                        helperText={
                          errorState['mobileNo'] +
                          " Please enter mobile number with '-'"
                        }
                        placeholder='Eg. 016-8889188 / 010-1112233'
                        required
                        id='mobileNo'
                        name='mobileNo'
                        label='Mobile Number'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl required fullWidth>
                        <InputLabel id='bankName'>Bank Name</InputLabel>
                        <Select
                          id='bankName'
                          name='bankName'
                          label='bankName'
                          value={formState['bankName']}
                          onChange={handleSelectChange}
                        >
                          <MenuItem value=''>
                            <em>None</em>
                          </MenuItem>
                          {banks.map(({ name }) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['bankAccNo']}
                        required
                        id='bankAccNo'
                        name='bankAccNo'
                        label='Bank Account Number'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={handleChange}
                        value={formState['bankBeneficialName']}
                        required
                        id='bankBeneficialName'
                        name='bankBeneficialName'
                        label='Bank Beneficial Name'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={handleChange}
                        value={formState['address']}
                        required
                        id='address'
                        name='address'
                        label='Address'
                        helperText='Your current address'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['city']}
                        required
                        id='city'
                        name='city'
                        label='City'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl required fullWidth>
                        <InputLabel id='state'>State</InputLabel>
                        <Select
                          id='state'
                          name='state'
                          label='State'
                          value={formState['state']}
                          onChange={handleSelectChange}
                        >
                          <MenuItem value=''>
                            <em>None</em>
                          </MenuItem>
                          {statess.map(({ name }) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['postcode']}
                        error={!!errorState['postcode']}
                        helperText={
                          errorState['postcode'] ||
                          `(${formState['postcode'].length}/5)`
                        }
                        required
                        id='postcode'
                        name='postcode'
                        label='Postal code'
                        inputProps={{ maxLength: 5 }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['country']}
                        required
                        id='country'
                        name='country'
                        label='Country'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['companyName']}
                        id='companyName'
                        name='companyName'
                        label='Company Name'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['companyRegNo']}
                        id='companyRegNo'
                        name='companyRegNo'
                        label='Company Register Number'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        onChange={handleChange}
                        value={formState['telNo']}
                        helperText={"Please enter tel number WITHOUT '-'"}
                        placeholder='Eg. 06123456 / 0887394900'
                        id='telNo'
                        name='telNo'
                        label='Tel Number'
                        fullWidth
                      />
                    </Grid>
                    {!inviteUsername && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          onChange={handleChange}
                          value={formState['superiorUsername']}
                          id='superiorUsername'
                          name='superiorUsername'
                          label='Superior Username'
                          helperText="Your superior's login username"
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <Alert severity='info' className='prose-sm'>
                        <AlertTitle>
                          Please upload the following documents
                        </AlertTitle>
                      </Alert>
                      <UserProfileAttachmentTable
                        files={files}
                        onChange={setFiles}
                      />
                    </Grid>
                  </Grid>
                </div>

                <Grid
                  className='text-right'
                  container
                  spacing={2}
                  justify='flex-end'
                >
                  <Grid item>
                    <Button
                      variant='text'
                      color='secondary'
                      size='large'
                      onClick={() => history.push('/')}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      size='large'
                      type='submit'
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Container>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackBarOpen.open}
          autoHideDuration={6000}
          onClose={handleClose}
          key='snackbar-error'
        >
          <Alert onClose={handleClose} severity='error'>
            {snackBarOpen.message}
          </Alert>
        </Snackbar>
      </div>
    )
}

SignUpPage.propTypes = {}

export default SignUpPage
