import KeyValue from './bean/keyValue'
import _ from 'lodash'

export class OrderUtils {
  static shouldTrim(propertyName: string) {
    return ['orderNo'].includes(propertyName)
  }

  static get installationPeriodDdl(): KeyValue<string, string>[] {
    return [
      {
        key: 'MORNING',
        value: 'Morning | 10:30AM - 12:30PM',
      },
      {
        key: 'AFTERNOON',
        value: 'Afternoon | 2:30PM - 5:30PM',
      },
    ]
  }

  static stringToOrderParser(input: string): Record<string, string> {
    function getNric(input: string[]): Record<string, string> {
      const a: string[][] = input.map(o => o.split(':').map(o1 => o1.trim()))

      const [, idType] = a.find(o => o[0] === 'Primary ID Type') || []
      const [, idValue] = a.find(o => o[0] === 'Primary ID Number') || []

      if (idType === 'Business Registration Number') {
        const [, name] = a.find(o => o[0] === 'Name') || []

        return {
          companyRegNo: idValue,
          companyName: name,
        }
      } else {
        if (idValue) return { nric: idValue }
        else return {}
      }
    }

    function getLoginId(input: string): Record<string, string> {
      const unifiRegex = /[a-zA-Z0-9\-._]+@(unifibiz|unifi)$/m

      const result = unifiRegex.exec(input)

      if (result?.length && result[0]) {
        return { loginId: result[0] }
      } else {
        return {}
      }
    }

    function isValidDate(input: string): boolean {
      const dateTimeRegex = /\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}/
      return dateTimeRegex.test(input.trim())
    }

    function parser(input: string): Record<string, string> | undefined {
      const barSeparator = ' | '

      if (input.startsWith('Order Number')) {
        const result = input.replace('Order Number', '').trim()
        if (!result) return
        return { orderNo: result }
      } else if (input.startsWith('Residential Voice')) {
        const result = input.replace('Residential Voice', '').trim()
        if (!result) return
        return { telNo: result }
      } else if (input.startsWith('Business Voice')) {
        const result = input.replace('Business Voice', '').trim()
        if (!result) return
        return { telNo: result }
      } else if (input.split(barSeparator).length === 2) {
        const [dateString, timeString] = input.split(barSeparator)

        const finalDateString = dateString.replace(
          'Installation Appointment',
          ''
        )

        const date = new Date(finalDateString).toISOString()
        return {
          appointmentDate: date,
          appointmentTime: timeString.toUpperCase(),
        }
      } else if (isValidDate(input)) {
        return { appointmentDate: new Date(input).toISOString() }
      }

      const [key, value] = input.split(':').map(o => o.trim())

      if (!value?.trim() || value === '-') return

      switch (key) {
        case 'Name':
          return { fullName: value }
        case 'Phone Number':
          return { mobileNo: [value.slice(0, 3), value.slice(3)].join('-') }
        case 'Email':
          return { email: value }
        case 'House / Unit / Lot':
          return { unitNo: value }
        case 'Floor Number':
          return { floorNo: value }
        case 'Building Name':
          return { buildingName: value }
        case 'Street Type':
          return { streetType: value }
        case 'Street Name':
          return { streetName: value }
        case 'Postcode':
          return { postcode: value }
        case 'Section':
          return { section: value }
        case 'City':
          return { city: value }
        case 'State':
          return { state: _.startCase(value.toLowerCase()) }
        case 'Account Number':
          return { accountNo: value }
        case 'Bundle Name':
          return { packageName: value }
        default:
          return undefined
      }
    }

    const parse: string[] = input.split('\n').filter(Boolean)

    const secondParse = parse
      .map(parser)
      .filter(Boolean)
      .reduce((pre, cur) => {
        return {
          ...pre,
          ...cur,
        }
      }, {})

    return {
      ...secondParse,
      ...getLoginId(input),
      ...getNric(parse),
    }
  }
}

export enum OrderUploadFileKey {
  MY_KAD = 'MY_KAD',
  CONVERSATION = 'CONVERSATION',
  UTILITY = 'UTILITY',
  SSM = 'SSM',
}
